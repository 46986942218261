// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-banheiro-jsx": () => import("./../../../src/pages/banheiro.jsx" /* webpackChunkName: "component---src-pages-banheiro-jsx" */),
  "component---src-pages-contato-index-jsx": () => import("./../../../src/pages/contato/index.jsx" /* webpackChunkName: "component---src-pages-contato-index-jsx" */),
  "component---src-pages-cozinha-jsx": () => import("./../../../src/pages/cozinha.jsx" /* webpackChunkName: "component---src-pages-cozinha-jsx" */),
  "component---src-pages-decoracao-jsx": () => import("./../../../src/pages/decoracao.jsx" /* webpackChunkName: "component---src-pages-decoracao-jsx" */),
  "component---src-pages-dormitorio-jsx": () => import("./../../../src/pages/dormitorio.jsx" /* webpackChunkName: "component---src-pages-dormitorio-jsx" */),
  "component---src-pages-escritorio-jsx": () => import("./../../../src/pages/escritorio.jsx" /* webpackChunkName: "component---src-pages-escritorio-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-sala-jsx": () => import("./../../../src/pages/sala.jsx" /* webpackChunkName: "component---src-pages-sala-jsx" */)
}

